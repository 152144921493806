import { computed, defineComponent } from 'vue';
export default defineComponent({
    name: 'LanguageSwitcher',
    props: { deUrl: String, enUrl: String },
    setup(props) {
        const deActive = computed(() => !props.deUrl);
        const enActive = computed(() => !props.enUrl);
        return { deActive, enActive };
    },
});
