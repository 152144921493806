import { defineComponent } from 'vue';
import { useModelWrapper } from '../../scripts/component.utility';
export default defineComponent({
    name: 'BurgerMenu',
    props: { open: Boolean },
    setup(props, { emit }) {
        const isOpen = useModelWrapper(props, emit, 'open');
        return { isOpen };
    },
});
