import { defineComponent, ref, watch } from 'vue';
import { useModelWrapper } from '../../scripts/component.utility';
import BaseSwitcher from '../atoms/BaseSwitcher.vue';
export default defineComponent({
    name: 'Navigation',
    props: { open: Boolean, deUrl: String, enUrl: String },
    components: { BaseSwitcher },
    setup(props, { emit }) {
        const isNavOpen = useModelWrapper(props, emit, 'open');
        const isLanguageSwitchActive = ref(!!props.deUrl);
        watch(isLanguageSwitchActive, (value) => {
            const url = value ? props.enUrl : props.deUrl;
            if (url)
                location.pathname = url;
        });
        return { isNavOpen, isLanguageSwitchActive };
    },
});
