import { defineComponent } from 'vue';
import { useModelWrapper } from '../../scripts/component.utility';
export default defineComponent({
    name: 'BaseSwitcher',
    props: { active: Boolean },
    setup(props, { emit }) {
        const isActive = useModelWrapper(props, emit, 'active');
        return { isActive };
    },
});
