import { useModelWrapper } from '../../scripts/component.utility';
import { computed, defineComponent } from 'vue';
export default defineComponent({
    name: 'FlyInWrapper',
    props: { rect: Object, active: Boolean },
    setup(props, { emit }) {
        const isFlyInActive = useModelWrapper(props, emit, 'active');
        // @ts-ignore
        const flyInPosition = computed(() => {
            if (!props.rect)
                return {};
            const { rect } = props;
            const { top, left, bottom, right } = rect;
            const topPos = (bottom || 0) + 20;
            const rightPos = window.innerWidth - (right || 0);
            return {
                '--top': `${topPos}px`,
                '--right': `${rightPos}px`,
                '--contact-top': `${top}px`,
                '--contact-left': `${left}px`,
            };
        });
        return { flyInPosition, isFlyInActive };
    },
});
