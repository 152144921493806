import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch, } from 'vue';
import GreenArrows from '../../assets/svg/green-arrows.svg';
import JobCheck from '../../assets/svg/job-check.svg';
import BurgerMenu from '../atoms/BurgerMenu.vue';
import ContactButton from '../atoms/ContactButton.vue';
import LanguageSwitcher from '../atoms/LanguageSwitcher.vue';
import FlyInWrapper from './FlyInWrapper.vue';
import Navigation from './Navigation.vue';
export default defineComponent({
    components: {
        GreenArrows,
        JobCheck,
        Navigation,
        LanguageSwitcher,
        BurgerMenu,
        ContactButton,
        FlyInWrapper,
    },
    props: {
        logo: String,
        logoMobile: String,
        homeUrl: String,
        deUrl: String,
        enUrl: String,
        isJobCheckPage: Boolean,
    },
    name: 'HeaderBar',
    setup() {
        const isSticky = ref(false);
        const isNavOpen = ref(false);
        const isFlyInActive = ref(false);
        const headerBar = ref(null);
        const contactButton = ref(null);
        const contactButtonRect = computed(() => (isFlyInActive.value || window.innerWidth) &&
            contactButton.value?.$el.getBoundingClientRect());
        watch(isNavOpen, (value) => {
            if (value)
                isFlyInActive.value = false;
        });
        watch(isFlyInActive, (value) => {
            const isMobile = matchMedia('(max-width: 1023px)').matches;
            if (value && isMobile)
                isNavOpen.value = false;
        });
        let observer = null;
        const initSticky = () => {
            if (!headerBar.value)
                return;
            if (!observer) {
                observer = new IntersectionObserver(([e]) => {
                    isSticky.value = e.intersectionRatio < 1;
                    // Break the endless resize loop
                    if (!isSticky.value) {
                        window.scrollTo(0, window.scrollY - 10);
                    }
                }, { threshold: [1] });
            }
            observer.observe(headerBar.value);
        };
        const scrollHandler = () => {
            isFlyInActive.value = false;
            isNavOpen.value = false;
        };
        window.addEventListener('scroll', scrollHandler);
        onBeforeUnmount(() => {
            window.removeEventListener('scroll', scrollHandler);
        });
        onMounted(() => initSticky());
        return {
            GreenArrows,
            JobCheck,
            isNavOpen,
            isFlyInActive,
            isSticky,
            headerBar,
            contactButton,
            contactButtonRect,
        };
    },
});
