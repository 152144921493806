import { defineComponent } from 'vue';
import { useModelWrapper } from '../../scripts/component.utility';
import BaseIcon from './BaseIcon.vue';
export default defineComponent({
    name: 'ContactButton',
    components: { BaseIcon },
    props: { open: Boolean },
    setup(props, { emit }) {
        const isFlyInActive = useModelWrapper(props, emit, 'open');
        return { isFlyInActive };
    },
});
